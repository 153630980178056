import React from "react"

import InvestorLogo from "@src/icons/v2/basis-set-logo.svg"
import OG from "@src/icons/og/OG_Investors.jpeg"

import Layout from "@src/components/Layout"
import CommunityCallout from "@src/components/CommunityCallout"
import Callout from "@src/components/Callout"
import getAppUrl from "@src/utils/getAppUrl"

import AngelInvestors from "./components/AngelInvestors"
import Splash from "../Features/components/Splash"

const Investors = ({ location }) => {
  const appUrl = getAppUrl.hook(location)
  return (
    <Layout
      title="Investors"
      description="Our partner's who are helping us redefine backend development"
      url="https://canonic.dev/investor"
      ogImage={OG}
    >
      <Splash
        title="We wouldn't have made it this far alone."
        subtitle="Basis Set Ventures is an early-stage investment firm focused on artificial intelligence and its practical application. They are our  institutional investors."
        illustrationComponent={<InvestorLogo />}
      />
      <AngelInvestors />
      <Callout location={location} title="investors" />
      <CommunityCallout />
    </Layout>
  )
}
export default Investors
