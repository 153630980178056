import React from "react"

import Text from "@src/components/Text"
import Section from "@src/components/Section"

import * as styles from "./AngelInvestors.module.scss"
import Esteban from "@src/icons/photos/esteban.png"
import Taylor from "@src/icons/photos/taylor.png"
import Mathew from "@src/icons/photos/mathew.png"
import Abhishek from "@src/icons/photos/abhishek.png"
import Arun from "@src/icons/photos/arun.png"
import Row from "../../../components/Row"
import Features from "../../../Features/components/Features"
const AngelInvestor = () => {
  return (
    <Row>
      <h2 className={styles.title}>Angel Investors</h2>
      <p className={styles.subtitle}>
        People helping us re-define backend development.
      </p>
      <Features
        numPerRow={5}
        size={200}
        features={[
          {
            title: "Esteban Reyes",
            subtitle: (
              <>
                Angel Investor
                <br />
                <strong>Stealth</strong>
              </>
            ),
          },
          {
            title: "Taylor Clauson",
            subtitle: (
              <>
                Angel Investor
                <br />
                <strong>Abstraction Capital</strong>
              </>
            ),
          },
          {
            title: "Mathew",
            subtitle: (
              <>
                Founder
                <br />
                <strong>Clutch.io</strong>
              </>
            ),
          },
          {
            title: "Abhishek Goyal",
            subtitle: (
              <>
                Co-Founder
                <br />
                <strong>Traxn</strong>
              </>
            ),
          },
          {
            title: "Arun Venkatachalam",
            subtitle: (
              <>
                Founder & CTO
                <br />
                <strong>Murugappa Group</strong>
              </>
            ),
          },
        ]}
      />
    </Row>
  )
}

export default AngelInvestor
